document.addEventListener('DOMContentLoaded', function () {
  // Маска полей ввода
  let phoneInputs = document.getElementsByClassName('input_phone');
  let phoneMaskOptions = {
    mask: '+{7} (000) 000-00-00'
  };

  Array.from(phoneInputs).forEach((input) => {
    // console.log(el.tagName);
    IMask(input, phoneMaskOptions);
  });
});